import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Constants from "../constants";
import * as Ant from "antd";
import styled from "styled-components";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension";
import { Divide } from "styled-icons/typicons";
import { SendPlane } from "@styled-icons/remix-fill/SendPlane";
import StaticImage from "../Components/StaticImage";
import Button from "../Components/Button";
import { navigate } from "gatsby";

// import { SendOutlined } from "@ant-design/icons";

const AboutItemArr = [
  { title: "預約諮詢", img: "/images/about/icon-1.png" },
  { title: "專人報價", img: "/images/about/icon-2.png" },
  { title: "審查稿件", img: "/images/about/icon-3.png" },
  { title: "印刷製作", img: "/images/about/icon-4.png" },
  { title: "印件交貨", img: "/images/about/icon-5.png" },
];

const Context = {
  sections: [
    {
      title: "感官文化印刷\nSENSATIONS PRINT",
      detail:
        "印刷，是一種五感體驗的集合，\n\n印刷作為設計的延伸，是將設計的視覺與概念實體化的重要角色。感官文化印刷的創辦人皆從設計相關科系畢業，我們了解設計與印刷之間的緊密關係、理解客戶對於質感的追求。在此之上致力於「印刷體驗」的實踐與各種印刷工藝效果的整合，以最高規格的態度面對每一件印刷作品。",
      image: "1.jpg",
      image_style: { height: 300, width: 300 },
    },
    {
      title: "印刷服務規劃",
      detail:
        "備有德國及⽇本原裝進⼝專業印刷機器，提供多元的打樣服務，以及各式印刷方式，包含數位印刷、平版印刷、UV印刷、網版印刷、凹凸版印刷與大圖輸出等服務。後加工如燙箔、打凹凸、上光上膜、匝型等，以及各類裝幀服務，滿足客戶大量印刷、少量客製，以及同業代工生產的印刷需求。",
      image: "2.jpg",
      image_style: { width: 600, height: "auto" },
    },
    {
      title: "印刷工序開發",
      detail:
        "一件印刷作品的背後，集結了眾多工序與流程，有著各種可能性來達成理想的印刷效果。我們樂於在各種不同的印刷經驗之上尋找新的製作與呈現方式，串連客戶、設計端與印刷技藝產業鍊，研發相關印刷工序與技術，持續精進並開發更多元的印刷模組，從中創造新的印刷經驗，讓印刷的可以有更多不同的面貌。",
      image: "",
      image_style: {},
    },
    {
      title: "線上訂購・線上印刷商店",
      detail:
        "除了現場印刷諮詢服務外，感官文化提供新的線上詢價平台，少量、大量皆可製作。線上詢價平台提供多元的產品類型，包含名片卡類、海報、書籍製作、信封、包裝盒、貼紙等內容。接著挑選自身理想的紙材，並依據自身需求選擇合適的印刷方式與後加工內容。最後，填寫相關個人資料後，即可進行估價。",
      image: "3.jpg",
      image_style: { width: 650, height: "auto" },
    },
    {
      title: "提供FSC環保印刷服務",
      detail:
        "感官文化印刷正式成為 FSC™ COC 的合格供應商，協助客戶挑選符合 FSC 驗證的紙張，並進行符合FSC產銷規範的印刷製造與加工，完成的印件會標示相關FSC商標，為產品增值、提升消費者的認同。",
      image: "6.jpg",
      image_style: { width: 766, height: "auto" },
    },
  ],

  contact_section: {
    title: "聯絡資訊",
    // remember to replace iframe's width and height to 100%
    map_html: "",
    // '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.0028901837118!2d121.56235021455373!3d25.033975983972372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442abb6da9c9e1f%3A0x1206bcf082fd10a6!2zMTEw5Y-w5YyX5biC5L-h576p5Y2A5L-h576p6Lev5LqU5q61N-iZnw!5e0!3m2!1szh-TW!2stw!4v1591247827212!5m2!1szh-TW!2stw" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>',
    infos: [
      {
        title: "客服電話",
        detail: "02-2736-7701",
        icon: "contact_icon_phone.png",
      },
      {
        title: "Facebook 粉絲專頁",
        detail: "感官文化印刷Sensations Print",
        icon: "contact_icon_fb.png",
      },
      {
        title: "LINE ID",
        detail: "@ssprint",
        icon: "contact_icon_line.png",
      },
      // {
      //   title: "蝦皮賣場",
      //   detail: "sensationsprint",
      //   icon: "contact_icon_shopee.png",
      // },
      {
        title: "電子信箱",
        detail: "service@ssprint.com.tw",
        icon: "contact_icon_email.png",
      },
    ],
  },
};

function AboutPage(props) {
  const { dimension, rwd } = useDimension();
  let { sections } = Context;
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;

  return (
    <Wrapper isPad={pad} rwd={rwd}>
      <div className="grid-background">
        <div className="banner">
          <div className="img-container">
            <StaticImage filename="about_banner.webp" style={styles.img} />
            <div className="banner-tag">
              <div>INFO / 關於感官</div>
            </div>
          </div>
        </div>
        {!mobile ? (
          <>
            {/* desktop or pad */}
            <Ant.Row className="section constraint">
              <Ant.Col
                className="img-container"
                span={8}
                style={{ padding: pad ? 0 : 60 }}
              >
                <Ant.Col className="ratio-100">
                  <StaticImage
                    filename={sections[0].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />
                  {/* <img
                    src={}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  /> */}
                </Ant.Col>
              </Ant.Col>
              <Ant.Col
                className="context"
                span={14}
                offset={1}
                style={{ height: "50%", padding: pad ? 0 : 60 }}
                data-delighter
              >
                <p className="title" style={{ fontSize: 32, marginTop: 40 }}>
                  {sections[0].title}
                </p>
                <p className="detail">{sections[0].detail}</p>
              </Ant.Col>
            </Ant.Row>

            <Ant.Row className="section constraint">
              <Ant.Col span={8}>
                <div
                  className="context"
                  style={{ height: "50%", padding: pad ? 0 : 60 }}
                  data-delighter
                >
                  <p className="title">{sections[1].title}</p>
                  <p className="detail">{sections[1].detail}</p>
                  <Button
                    style={{ margin: 0 }}
                    onClick={
                      () => navigate("/quote")
                      // window.open("https://line.me/R/ti/p/@ssprint", "_blank")
                    }
                  >
                    線上專人詢價
                  </Button>
                </div>
                <div
                  className="context"
                  style={{
                    height: "50%",
                    padding: pad ? 0 : 60,
                    marginTop: rwd === "pad" || rwd === "desktop" ? 40 : 0,
                  }}
                  data-delighter
                >
                  <p className="title">{sections[2].title}</p>
                  <p className="detail">{sections[2].detail}</p>
                  <Button
                    style={{ margin: 0 }}
                    onClick={() => navigate("/articles")}
                  >
                    質感印件分享
                  </Button>
                </div>
              </Ant.Col>
              <Ant.Col
                className="img-container"
                span={14}
                offset={1}
                style={{ padding: pad ? 0 : 60 }}
              >
                <div className="ratio-130">
                  <StaticImage
                    filename={sections[1].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />

                  {/* <img
                    src={}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  /> */}
                </div>
              </Ant.Col>
            </Ant.Row>

            <Ant.Row className="section constraint">
              {" "}
              {/* special case for grid background */}
              <Ant.Col
                className="img-container"
                span={12}
                style={{ padding: pad ? 0 : 60 }}
              >
                <div className="ratio-75">
                  <StaticImage
                    filename={sections[3].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />

                  {/* <img
                    src={}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  /> */}
                </div>
              </Ant.Col>
              <Ant.Col className="context" span={10} offset={2} data-delighter>
                <p className="title">{sections[3].title}</p>
                <p className="detail">{sections[3].detail}</p>
                <Button
                  style={{ margin: 0 }}
                  onClick={() => navigate("/products")}
                >
                  購買設計商品
                </Button>
              </Ant.Col>
            </Ant.Row>

            <Ant.Row
              className="section constraint"
              style={{ paddingBottom: 0 }}
            >
              {" "}
              {/* special case for grid background */}
              <Ant.Col
                className="context"
                span={8}
                data-delighter
                style={{ padding: pad ? 0 : 60 }}
              >
                <p className="title">{sections[4].title}</p>
                <p className="detail">{sections[4].detail}</p>
                <Button
                  style={{ margin: 0 }}
                  onClick={() =>
                    navigate("/article/4870354f-e5a7-4e36-9b0f-0a0253abc9f5")
                  }
                >
                  了解更多FSC印刷資訊
                </Button>
              </Ant.Col>
              <Ant.Col className="img-container" offset={2} span={14}>
                <div className="ratio-58">
                  <StaticImage
                    filename={sections[4].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />
                </div>
              </Ant.Col>
            </Ant.Row>
          </>
        ) : (
          <>
            {/* mobile */}
            <Ant.Col className="section constraint">
              <Ant.Col
                className="img-container"
                span={14}
                style={{ marginTop: 40, marginBottom: 40 }}
              >
                <div className="ratio-100">
                  <StaticImage
                    filename={sections[0].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />

                  {/* <img
                    src={}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  /> */}
                </div>
              </Ant.Col>
              <Ant.Col className="context" data-delighter>
                <p className="title" style={{ fontSize: 28 }}>
                  {sections[0].title}
                </p>
                <p className="detail">{sections[0].detail}</p>
              </Ant.Col>
            </Ant.Col>

            <Ant.Col
              className="section constraint"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="context" data-delighter>
                <p className="title">{sections[1].title}</p>
                <p className="detail">{sections[1].detail}</p>
                <Button
                  style={{ margin: 0 }}
                  onClick={() =>
                    // window.open("https://line.me/R/ti/p/@ssprint", "_blank")
                    navigate("/quote")
                  }
                >
                  線上專人詢價
                </Button>
              </div>
              <div
                className="img-container"
                style={{
                  width: "80%",
                  alignSelf: "flex-end",
                  marginTop: 40,
                  marginBottom: 40,
                }}
              >
                <div className="ratio-130">
                  <StaticImage
                    filename={sections[1].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />

                  {/* <img
                    src={}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  /> */}
                </div>
              </div>

              <div className="context" data-delighter>
                <p className="title">{sections[2].title}</p>
                <p className="detail">{sections[2].detail}</p>
                <Button
                  style={{ margin: 0 }}
                  onClick={() => navigate("/articles")}
                >
                  質感印件分享
                </Button>
              </div>
            </Ant.Col>

            <Ant.Col className="section constraint">
              <Ant.Col className="context" data-delighter>
                <p className="title">{sections[3].title}</p>
                <p className="detail">{sections[3].detail}</p>
                <Button
                  style={{ margin: 0 }}
                  onClick={() => navigate("/products")}
                >
                  購買設計商品
                </Button>
              </Ant.Col>{" "}
              {/* special case for grid background */}
              <Ant.Col className="img-container" style={{ marginTop: 40 }}>
                <div className="ratio-75">
                  <StaticImage
                    filename={sections[3].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />

                  {/* <img
                    src={}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  /> */}
                </div>
              </Ant.Col>
            </Ant.Col>
            <Ant.Col
              className="section constraint"
              style={{ paddingBottom: 0 }}
            >
              <Ant.Col className="context" data-delighter>
                <p className="title">{sections[4].title}</p>
                <p className="detail">{sections[4].detail}</p>
                <Button
                  style={{ margin: 0 }}
                  onClick={() =>
                    navigate("/article/4870354f-e5a7-4e36-9b0f-0a0253abc9f5")
                  }
                >
                  了解更多FSC印刷資訊
                </Button>
              </Ant.Col>
              <Ant.Col className="img-container">
                <div className="ratio-58">
                  <StaticImage
                    filename={sections[4].image}
                    style={styles.img}
                  />
                  <div className="mask" style={styles.img} data-delighter />
                </div>
              </Ant.Col>
            </Ant.Col>
          </>
        )}
      </div>

      <div
        className="service-flow constraint"
        style={{ marginTop: 80 }}
        data-delighter
      >
        <p className="title">印刷服務流程</p>
        <div
          className="row"
          style={{
            marginBottom: 30,
            flexDirection: rwd === "mobile" ? "column" : "row",
          }}
        >
          {AboutItemArr.map((item, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                flexDirection: rwd === "mobile" ? "column" : "row",
                alignItems: "center",
              }}
            >
              <AboutItem rwd={rwd}>
                <div className="about-img-wrapper">
                  <img className="about-img" src={item.img} />
                </div>
                <p>{item.title}</p>
              </AboutItem>
              {idx !== AboutItemArr.length - 1 && (
                <img
                  src={
                    rwd === "mobile"
                      ? "/images/about/arrow-down.png"
                      : "/images/about/arrow.png"
                  }
                  width={rwd === "mobile" ? "8" : "42"}
                  height={rwd === "mobile" ? "35" : "12"}
                  style={
                    rwd === "mobile" ? { marginLeft: 90 } : { marginBottom: 50 }
                  }
                />
              )}
            </div>
          ))}
        </div>
        <p className="detail">{`感官提供現場印刷諮詢服務，有諮詢需求者可以透過「專人詢價專區」、FB、IG與LINE官方帳號進行線上預約，將有專人為您服務。
完成現場諮詢後，報價需要約2-3個工作天（不含假日）。簽署報價單後進行審稿作業，以確認稿件是否符合印刷規範。 
完成審稿後開始印製並交貨，印製期會依印件狀況而有所不同，交貨時間若有指定亦可於印件需求中提出。
填寫印件需求：1.印件規格 / 2.個人資料
交貨時間備註：數位印刷、直噴類審稿完成後製作期約7-10個工作天，
獨立版類則為12-15個工作天，以上不包含打樣確認時間。
`}</p>
        <Button
          onClick={() =>
            // window.open("https://line.me/R/ti/p/@ssprint", "_blank")
            navigate("/quote")
          }
          style={{
            marginTop: rwd === "desktop" ? 60 : 50,
            marginBottom: rwd === "desktop" ? 70 : 60,
            width: rwd === "desktop" ? 420 : 343,
          }}
        >
          <span style={{ marginRight: 5 }}>{`專人詢價專區`}</span>
          <SendPlane size={22} color={"#cccccc"} />
        </Button>
      </div>
      <div className="articles constraint" style={{ marginBottom: 20 }}>
        <div
          style={{
            margin: rwd === "mobile" ? "0px 0px 60px 0px" : "0px 60px 0px 0px",
          }}
        >
          <div className="img-container">
            <StaticImage filename="article-1.jpg" style={styles.img} />
            <div className="mask" style={styles.img} data-delighter />
          </div>
          <h3 data-delighter>線上訂購‧線上印刷商店</h3>
          <p data-delighter>{`提供種類多元的客製化線上印刷服務，
並與不同領域的設計師合作印製風格印刷商品，送禮自用首選。
滿足您各式的印刷需求，為生活增添設計質感與新意。`}</p>
          <a href={`/products`}>訂製印刷品／尋找風格選物 {">"}</a>
        </div>
        <div>
          <div className="img-container">
            <StaticImage filename="article-2.jpg" style={styles.img} />
            <div className="mask" style={styles.img} data-delighter />
          </div>
          <h3 data-delighter>跨越印刷銀河系｜設計人必懂的印刷學</h3>
          <p data-delighter>{`印刷不是只有一種解答，感官文化與 YOTTA合作的課程，
帶你從零開始入門理解印刷大小事。一起學會紙、版、印、裝、工等必備觀念，
打造出具備個人風格的獨特成品！`}</p>
          <a
            href="https://www.yottau.com.tw/teacher/1688"
            target="_blank"
            rel="noreferrer"
          >
            線上學習印刷 {">"}
          </a>
        </div>
      </div>
      <div
        className="contact"
        style={{ marginBottom: 70, marginTop: rwd === "mobile" ? 0 : 80 }}
        data-delighter
      >
        <p className="title">聯絡感官</p>
        <p className="bold">02-2736-6566</p>
        <p className="detail">{`週一至週五上午10點至下午7點（例假日休息）
新北市中和區板南路496號3樓
Email｜service@ssprint.com.tw
LINE ID｜@ssprint
`}</p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;

  & .constraint {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0 auto;
  }  

  & .img-container {
    & .mask.delighter {
      opacity: 1;
      background-color: #d6e3e3;
      transition: opacity 0.6s ease-out;
    }

    & .mask.delighter.started {
      opacity: 0;
    }
  }

  & > .articles {
    display: flex;
    flex-direction: ${props => (props.rwd === "mobile" ? "column" : "row")};
    padding: ${props => (props.isPad ? `0 ${Theme.rwdPadding.large} ` : 0)};

    & > div {
      flex: 1;

      & > h3 {
        padding: 30px 0px 22px;
        font-weight: normal;
        font-size: ${props => (props.isPad ? "21px" : "18px")};
        color: ${Theme.colors.main};
        border-bottom: 0.5px solid ${Theme.colors.text};
      }
      & > p {
        font-size: 14px;
        line-height: 28px;
        color: ${Theme.colors.text};
      }
      & > a {
        font-size: 16px;
        color: ${Theme.colors.blueGreen};
      }
      & > .img-container {
        position: relative;
        height: ${props => {
          if (props.rwd === "desktop") {
            return "218px";
          } else if (props.rwd === "pad") {
            return "110px";
          } else {
            return "110px";
          }
        }};
      }

      & > h3.delighter,p.delighter {
        transform: translateY(30px);
        transition: all 0.4s ease-out;
        opacity: 0;
      }
      & > h3.delighter.started,p.delighter.started {
        transform: none;
        opacity: 1;
      }
    }
  }

  & > .grid-background {
    /* background: linear-gradient(to right, #f6f6f6 60%, #fff 50%); */
    background: linear-gradient(
        45deg,
        #f6f6f6 50%,
        transparent 50%,
        transparent 0%,
        #f6f6f6 0%
      ),
      linear-gradient(
        45deg,
        #f6f6f6 50%,
        transparent 50%,
        transparent 0%,
        #f6f6f6 0%
      );
    background-size: 75% 62%;
    background-position: 0 0, 300% 163%;
    background-repeat: no-repeat;

    & > .banner {
      overflow: hidden;
      & > .img-container {
        position: relative;
        height: ${props => {
          if (props.rwd === "desktop") {
            return "520px";
          } else if (props.rwd === "pad") {
            return "280px";
          } else {
            return "160px";
          }
        }};
        max-width: ${Theme.centerContentMaxWidth};
        margin: 0px auto;
        & > .banner-tag {
          ${Theme.bannerTagStyle}
          background-color: #ffffff;
          & > div {
            color: #9b9b9b;
          }
        }
      }

      /* @media screen and (max-width: 600px) {
        height: 300px;
      } */
    }
  }

  & .section {
    padding: ${props => (props.isPad ? Theme.rwdPadding.large : 0)};
    padding-bottom:${props =>
      props.rwd === "mobile" ? 0 : Theme.rwdPadding.large}px;
    & .context {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & .title {
        ${Theme.textTitleStyle}
        font-size: ${props => (props.rwd === "mobile" ? 21 : 24)}px;
        white-space: pre-wrap;
        line-height: 42px;
      }

      & .detail {
        ${Theme.textContentStyle}
        white-space: break-spaces;
      }
    }

    & .context.delighter {
      transform: translateY(50px);
      transition: all 0.8s ease-out;
      opacity: 0;
    }
    & .context.delighter.started {
      transform: none;
      opacity: 1;
    }

    /* image containers */
    & > .img-container {
      & > .ratio-100 {
        position: relative;
        width: 100%;
        padding-top: 100%;
        /* padding-top: 33%; XXX */
      }
      & > .ratio-75 {
        position: relative;
        width: 100%;
        padding-top: 75%; /* XXX */
      }
      & > .ratio-130 {
        position: relative;
        width: 100%;
        padding-top: 130%;
      }
      & > .ratio-58 {
        position: relative;
        width: 100%;
        padding-top: 58%;
      }
    }
    
  }

  & > .service-flow {
    padding: ${props => (props.isPad ? `0 ${Theme.rwdPadding.outer}` : 0)};

    &.delighter {
      transform: translateY(50px);
      transition: all 0.8s ease-out;
      opacity: 0;
    }
    &.delighter.started {
      transform: none;
      opacity: 1;
    }

    & > .title {
      ${Theme.textTitleStyle}
      font-size: 32px;
      text-align: center;
    }

    & > .detail {
      ${Theme.textContentStyle}
      text-align: center;
      white-space: break-spaces;
    }

    & > .row {
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        // arrow
        margin-bottom: 45px;
      }
    }
  }

  & > .contact {
    padding: ${props => (props.isPad ? `0 ${Theme.rwdPadding.large}` : 0)};

    &.delighter {
      transform: translateY(50px);
      transition: all 0.8s ease-out;
      opacity: 0;
    }
    &.delighter.started {
      transform: none;
      opacity: 1;
    }

    & > .title {
      ${Theme.textTitleStyle}
      font-size: 21px;
      text-align: center;
    }

    & > .bold {
      font-weight: bold;
      font-size: 21px;
      text-align: center;
    }

    & > .detail {
      ${Theme.textContentStyle}
      text-align: center;
      white-space: break-spaces;
    }
  }
`;

const AboutItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.rwd === "mobile" ? "row" : "column")};
  align-items: center;
  text-align: center;
  padding: ${props => (props.rwd === "mobile" ? "0px" : "10px")};
  & > p {
    margin-top: 13px;
    margin-left: ${props => (props.rwd === "mobile" ? "15px" : "0px")};
  }
  & > .about-img-wrapper {
    position: relative;
    width: ${props => (props.rwd === "desktop" ? "100px" : "80px")};
    height: ${props => (props.rwd === "desktop" ? "100px" : "80px")};
    & > .about-img {
      background-size: contain;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: ${props => `url(${props.img})`};
    }
  }
`;

const styles = {
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
};

export default connect(null, ActionCreator)(AboutPage);
